@import url('../colors.css');

/* Body styles */
body {
  margin: 0;
  font-family: 'Courier New', serif;
}

/* Main container styles */
.main-container {
  background: var(--body-color);
  min-height: 100vh;
}

/* Content styles */
.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center content horizontally */
  justify-content: center;
  /* Center content vertically */
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  /* Allow cards to wrap to the next row */
  justify-content: center;
  /* Center cards horizontally */
  margin-top: 10%;
}

.card {
  background-color: var(--gray-color) !important;
  width: 400px;
  max-width: 90%;
  /* Adjust the width for responsiveness */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin-left: 20px;
  transition: transform 0.3s ease;
  /* Add transition for smooth effect */
}

.card:hover,
.dropdown:hover {
  transform: scale(1.05);
  /* Scale up slightly on hover */
}



.card-title {
  font-family: 'Didot', serif;
  font-size: 24px;
  margin-bottom: 10px;
}

.card-content {
  font-family: Arial, sans-serif;
  /* You can change this font if you want */
  font-size: 16px;
  line-height: 1.6;
}

.dropbtn {
  background-color: var(--gray-color);
  color: black;
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'Didot', serif;
  border: none;
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

.home-month-select {
  /* Reset Select */
  appearance: none;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;
  color: black;
  background-color: var(--darkgray);
  cursor: pointer;
}

/* Custom Select wrapper */
.dropdown {
  background-color: var(--gray-color);
  position: fixed;

  display: flex;
  bottom: 5px;
  width: 20em;
  height: 3em;
  border-radius: .25em;
  overflow: hidden;
  transition: transform 0.3s ease;
  /* Add transition for smooth effect */
}

/* Arrow */
.dropdown::after {
  content: '\25BC';
  position: absolute;
  top: -2.5px;
  right: 0;
  padding: 1em;
  background-color: var(--gray-color);
  transition: .25s all ease;
  pointer-events: none;
}

.dropdown:hover::after {
  color: var(--header-color);
}

.dropvalue {
  color: black;
  background-color: var(--gray-color);
  padding: 10px 20px;
  text-decoration: none;
  display: block;
  border: none;
  cursor: pointer;
}

.main-page-loading-label {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

@media screen and (max-width: 600px) {
  .dropdown {
    position: fixed;
    left: 0;
    width: 100%;
    margin-bottom: 13px;
  }

  .card {
    max-width: 100%;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    /* Change flex-direction to column */
    margin-top: 0;
    margin-left: 0;
  }

  .card {
    margin-left: 0;
  }

  .card:hover,
  .dropdown:hover {
    transform: scale(1);
    /* Scale up slightly on hover */
  }
}