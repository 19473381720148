/* Header styles */
@import url('./colors.css');

header {
  background-color: var(--header-color);
  /* Dark blue */
  color: white;
}

.header-content {
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content:space-between;
  /* Distribute items evenly */
}

.logo {
  cursor: pointer;
  width: 125px;
  /* Adjust the width as needed */
  height: 80px;
  /* Adjust the height as needed */
  border-radius: 5%;
  /* Makes the edges rounded */
  margin-right: auto;
}

nav {
  margin-right: auto;
  /* Pushes the navigation links to the right */
}

.menu-icon {
  display: none;
  /* Hide menu icon by default */
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  /* Use flexbox */
  justify-content: center;
  align-items: center;
  /* Center items vertically */
}

.sign-out-button-wide-medium,
.sign-out-button-small-medium {
  margin-left: auto;
  border: 0;
  background-color: #634114c5;
  color: white;
}

.sign-out-button-small-medium {
  display: none;
}

nav ul li,
.sign-out-button-wide-medium {
  margin-left: 20px;
  cursor: pointer;
  padding-inline: 20px;
  font-family: 'Didot', serif;
}

li:hover,
.sign-out-button-wide-medium:hover {
  background-color: var(--body-color);
  transform: scale(1.05);
  /* Change to the desired highlight color */
}

.header-link {
  color: white;
  text-decoration: none;
}

nav ul li.active {
  background-color: var(--body-color);
  /* Change to the desired highlight color */
}

/* Media Query for Mobile Devices */
@media screen and (max-width: 600px) {
  .header-titles ul {
    display: none;
    /* Hide the links */
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    margin-right: 10px;
    width: 100%;
    background-color: var(--header-color);
    z-index: 1;
    padding: 0;
    /* Remove default padding */
    border-radius: 0.5rem;
    /* Add some rounded corners for better appearance */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Add a shadow for depth */
  }

  nav {
    margin-right: -1%;
    /* Pushes the navigation links to the right */
  }

  nav ul {
    flex-direction: column;
    display: flex;
    position:fixed;
    margin-inline-start: -50%;
    align-items: center;
    /* Center content horizontally */
    justify-content: center;
    /* Center content vertically */
    top: auto;
    width: 61%;
    background-color: #8e7049;
    z-index: 1;
    /* Remove default padding */
    border-radius: 0.5rem;
    /* Add some rounded corners for better appearance */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Add a shadow for depth */
  }

  

  .header-titles-show-menu ul li {
    margin: 0;
    /* Remove margin */
    padding: 10px;
    /* Add padding for better touch response */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    /* Add a border between links */
  }

  .sign-out-button-small-medium {
    background-color: #8e7049;
    margin-left: 0;
    display: block;
  }

  .sign-out-button-wide-medium {
    display: none;
  }

  .menu-icon {
    display: block;
    /* Show the menu icon */
    cursor: pointer;
  }

  

  .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
    transition: transform 0.4s, opacity 0.4s;
    /* Adjusted transition properties */
  }

  .menu-icon.active .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .menu-icon.active .bar:nth-child(2) {
    opacity: 0;
  }

  .menu-icon.active .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  nav ul li.active {
    width: 100%;
  }
}