@import url('../colors.css');

.inventory-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
}

.inventory-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Add a stronger box shadow */
  border: 2px solid #166390; /* Add a border to highlight the modal */
  font-family: 'Didot', serif;
  height: auto;
  width: auto;
}

.inventory-popup-close-button {
  position: relative;
  left: 50%;
  bottom: 10px;
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  width: 34px;
  height: 34px;
}

.inventory-popup-close-button:hover {
  color: #ff0000;
}

.inventory-popup-text {
  font-size: 20px;
}

.inventory-popup-label {
  font-size: 24px;
  font-weight: bold;
}

.inventory-popup-confirm-button {
  transition: transform 0.25s ease;
  width: 30%;
  padding: 8px;
  border: 2px solid rgba(0, 0, 0, 0.457);
  border-radius: 4px;
  box-sizing: border-box;
  background-color: rgba(43, 255, 0, 0.637);
  margin-top: 10px;
  height: 40px;
  color: black;
}

.inventory-popup-confirm-button:hover {
  transform: scale(1.04);
}

.inventory-popup-quantity-input {
  margin-left: 10px;
  border: 2px solid #ccc;
  border-radius: 4px;
  width: 15%;
}

.inventory-popup-quantity-change-group {
  margin-bottom: 7px;
}

@media screen and (max-width: 600px) {
  .inventory-popup-confirm-button:hover {
    transform: scale(1);
  }

  .inventory-popup-text,
  label {
    font-size: 20px;
    font-family: 'Times New Roman', serif;
  }
}
