.signinContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh; /* Adjust the height as needed */
}

.signinContainer__box__form {
  display: flex;
  flex-direction: column;
  max-width: auto;
  align-items: center;
  justify-content: center;
}

.signinContainer__box__form input {
  margin-bottom: 7px;
  border-radius: 3px;
}

.signinContainer__box__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

h1 {
  margin-bottom: 30px;
}

.sign-in-button:hover {
  transform: scale(1.05);
}

.sign-in-button {
  transition: transform 0.25s ease;
  border-radius: 3px;
}